
//const baseUrl: string = "http://rtixt-pos-api.ti-plus.net";
const baseUrl: string = "https://tmp-pos-api.ithub-webhost.net";
const name: string = "RT IXT POS";

export const environment = {
  production: true,
  appName: `${name}`,
  apiUrl: `${baseUrl}/api`,
  ticketsUrl: `${baseUrl}/Tickets`,
  cashClosingUrl: `${baseUrl}/CashClosing`,
  customerStatementsUrl: `${baseUrl}/CustomerStatements`,
  CSVTemplate: `${baseUrl}/csv-files/ITHUB-POS-PLANTILLA-PRODUCTOS.xlsx`,
};
